import {
  pauseVideo,
  setPlayPauseIcons,
  addMediaPlayerEvents,
  resetProgressBar,
  resetReactions,
  updateProgressBar,
  updateTimeElapsed,
  checkPlayerPlayPauseIcon,
  getMinuteSecondsFormat,
  onProgressBarClick,
  playNextAudio,
  checkIfHasNext,
  checkIfHasPrevious,
  loadReactions,
  updateMediaPlayerData,
  getMediaPlayerData
} from '../utils/media';

window.audio = null;
let currentTime;
let customBarTimeInterval = null;

function enableProgressBar(audio) {
  customBarTimeInterval = window.setInterval(function() {
    currentTime = audio.currentTime;
    updateProgressBar(currentTime, 'audio');
    updateTimeElapsed(currentTime, 'audio');
  }, 250);
}

function progressBarClickEvent(e) {
  onProgressBarClick(e, true, 'audio');
}

function addProgressBarClickEvent() {
  const progressBar = document.querySelector('#media-player .playthrough__bar')
  progressBar.addEventListener('click', progressBarClickEvent);
}

function removeProgressBarClickEvent() {
  const progressBar = document.querySelector('#media-player .playthrough__bar')
  progressBar.addEventListener('click', progressBarClickEvent);
}

function updateAudioDuration(audio) {
  const duration = audio.duration || 0;
  document.querySelector('.total-time-audio').innerText = getMinuteSecondsFormat(duration);
}

function onAudioPause(audio) {
  audio.onpause = function() {
    setPlayPauseIcons(window.audio);
    checkPlayerPlayPauseIcon();
    clearInterval(customBarTimeInterval);
  };
}

function onAudioEnds(audio) {
  audio.onended = function() {
    setPlayPauseIcons(window.audio);
    checkPlayerPlayPauseIcon();
    clearInterval(customBarTimeInterval);
    playNextAudio(audio);
  };
}

function onAudioError(audio) {
  audio.onerror = function() {
    resetProgressBar();
    updateTimeElapsed(0, 'audio');
    setPlayPauseIcons(window.audio);
    checkPlayerPlayPauseIcon();
    removeProgressBarClickEvent();
    clearInterval(customBarTimeInterval);
  }
}

function onAudioLoadsMetadata(audio) {
  audio.onloadedmetadata = function() {
    updateAudioDuration(audio);
    addProgressBarClickEvent();
  }
}

function onAudioPlay(audio) {
  audio.onplay = function() {
    enableProgressBar(audio)
    updateAudioDuration(audio);
  }
}

function avoidMediaCardServerClickAgain(e) {
  e.stopPropagation();
  e.preventDefault();

  const mediaId = e.currentTarget.dataset?.id;
  document.querySelector('#media-player').setAttribute('data-id', mediaId);
  window.startStopAudio();
  return false;
}

function removeAllMediaCardEvents() {
  document.querySelectorAll('.media-card').forEach(function(mediaCard) {
    mediaCard.removeEventListener('click', avoidMediaCardServerClickAgain);
  });;
}

function addMediaCardEvent(id) {
  document.querySelectorAll(`.media-card-${id}`).forEach(function(mediaCard) {
    if (mediaCard.offsetWidth > 0 || mediaCard.offsetHeight > 0) {
      removeAllMediaCardEvents();
      mediaCard.addEventListener('click', avoidMediaCardServerClickAgain);
    }
  });
}

window.startStopAudio = function(currentTarget) {
  currentTarget = currentTarget || document.querySelector('#media-player');
  const audio = currentTarget.querySelector('audio');
  window.audio = audio;

  clearInterval(customBarTimeInterval);
  onAudioError(audio);
  onAudioPause(audio);
  onAudioPlay(audio);
  onAudioEnds(audio);
  onAudioLoadsMetadata(audio);

  checkIfHasNext(currentTarget);
  checkIfHasPrevious(currentTarget);

  if (audio.paused) {
    pauseVideo();
    resetReactions();
    addMediaPlayerEvents('audio');
    addProgressBarClickEvent()
    audio.play();
  } else {
    audio.pause();
  }

  setPlayPauseIcons(audio);
  checkPlayerPlayPauseIcon();

  getMediaPlayerData('Audio', currentTarget.dataset.id).then(function({ image, song, artist, artistUrl }) {
    updateMediaPlayerData({ image, artist, song, artistUrl, mediaId: currentTarget.dataset.id });
  });
  loadReactions(currentTarget.dataset.id, 'audio');
  addMediaCardEvent(currentTarget.dataset.id);
}
